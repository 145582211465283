'use strict';

import {CSSProperties, forwardRef, KeyboardEventHandler, LegacyRef, MouseEventHandler, ReactElement, SyntheticEvent} from 'react';
import './IconButton.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import debounce from 'debounce';
import {Tooltip} from "@mui/material";
import {isApple, isMobileView} from "../../lib/picos/browserFns";
import Icons, {IconSizes, IconType} from "./Icons";
import {withErrorBoundary} from 'react-error-boundary'
import debounceRender from "react-debounce-render";
import {RotateProp} from "@fortawesome/fontawesome-svg-core";
import {motion} from 'framer-motion';

// Layering
export const LAYERS_COUNTER = {style: 'LayersCounter', tags: ['layer', 'counter'], icon: 'fa-layers fa-fw'};

export interface IconButtonProps {
    // Alt text for the icon
    alt?: string;

    // Should this button grab the focus?
    autoFocus?: boolean;

    // Make the icon shake like an alarm
    shake?: boolean;

    // Make the icon pulsate
    beat?: boolean;

    // Beat and fade the icon
    beatFade?: boolean;

    // Fade the icon in and out
    fade?: boolean;

    // Make the item spin continuously
    spin?: boolean;

    // Additional classes to add for the button
    className?: string;

    // Should click events NOT propagate to the containing element?
    clickPassthrough?: boolean;

    // Custom color for the icon (NOT the label)
    color?: string;

    // Should this button use data-private attribute?
    encrypted?: boolean;

    // Custom color for the label
    textColor?: string;

    // Should a number be shown in a little red circle at the top right of this icon?
    count?: number;

    // Color of the count background
    countBackground?: string;

    // Extra data to pass back on click
    data?: object;

    // Should this button be disabled?
    disabled?: boolean;

    // If using a flag, what color should the flag be?
    flagColor?: string;

    // Should this button show a flag (exclamation point) in the top left?
    flagged?: boolean;

    // Is this button hidden?
    hidden?: boolean;

    // Unique id for this button
    id?: string;

    // Should we ignore clicking on the text (label)?
    ignoreTextClick?: boolean;

    // Is this a submit button, rather than a normal button? If so, hitting <enter> will call the onClick handler.
    isSubmit?: boolean;

    // A text label to add to the right of the icon
    label?: string | ReactElement;

    // Bolds the label text
    labelBold?: boolean;

    // Puts the label to the left of the icon, rather than the right
    labelOnLeft?: boolean;

    // onClick callback
    onClick?: (event: MouseEventHandler | any) => void;

    // Show the button as pressed?
    pressed?: boolean;

    // For duotone icons, this is the primary color
    primaryColor?: string;

    // For duotone icons, this is the secondary color
    secondaryColor?: string;

    // For duotone icons, this is the opacity (0-1) of the secondary color
    secondaryOpacity?: number;

    // Size of the button
    size?: IconSizes;

    // Additional styles to add to the button (overrides everything else)
    style?: CSSProperties;

    // Set the width of the icon
    svgWidth?: number; // Optional width (in em) for the svg icon

    // Give the button a tabIndex to specify order of tabbing through the UI
    tabIndex?: number;

    // A hint to be shown when hovering over the icon
    title?: string | ReactElement;

    // A constant (from the list of const members) for this icon, e.g. COPY
    type: IconType;

    // Use a setTimeout call to call this button. Useful if you want to process onBlur events before processing onClick.
    useDelay?: boolean; // allow other events to finish up before starting this one
}

export interface IconTypeItem extends IconType {
    name: string;
}

type IconKey = keyof Icons;

const ICON_NAMES: IconKey[] = Object.keys(Icons) as IconKey[];

// @ts-ignore
export const ICONS: IconTypeItem[] = ICON_NAMES
    .filter((m: IconKey) => (Icons[m] as IconType).hasOwnProperty('reactIcon'))
    .filter((m: IconKey) => !['SPINNER', 'BLANK'].includes(m))
    .map((m: keyof Icons) => {
        return {name: m, ...(Icons[m as IconKey] as IconType)};
    });

/**
 * Nice icon button with lots of features.
 * @props {object} props
 * @returns {React.JSX.Element|null}
 * @constructor
 */
const IconButton = withErrorBoundary(
    forwardRef((props: IconButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
            /**
             * We handle click events, including shift-click and alt/cmd-click
             * @param {MouseEvent} e
             */
            const _onClick = debounce((e: SyntheticEvent) => {
                e.persist();

                const _doIt = debounce(() => {
                    if (!props.clickPassthrough) {
                        e.stopPropagation();
                        e.preventDefault();
                    }

                    if (props.onClick) {
                        try {
                            if (props.data) props.onClick(props.data);
                            else props.onClick(e);
                        }
                        catch (err) {

                        }
                    }
                }, 50);

                // Important: there is an issue where clicking on a button will fire its onClick handler BEFORE any other
                // element's onBlur handler. For example, in InvoiceDetails we can directly modify the invoiceNotes, but if we
                // then click on the Edit button, the onBlur handler will not fire until AFTER we have closed the TaskItem form!
                // To fix this, we need to give the UI enough time to handle the onBlur event.  1ms is not enough, 100ms seems
                // to do the trick without any noticeable lag.
                if (props.useDelay) {
                    setTimeout(_doIt, 100);
                }
                else {
                    _doIt();
                }
            }, 100, false);

            const onClicked = debounce(_onClick, 200, true);

            const title = isMobileView() ? null : props.title;

            let size: string;
            switch (props.size) {
                case 'xlarge':
                    size = 'IconSizeXLarge';
                    break;
                case 'large':
                    size = 'IconSizeLarge';
                    break;
                case 'small':
                    size = 'IconSizeSmall';
                    break;
                case 'smaller':
                    size = 'IconSizeSmaller';
                    break;
                case 'normal':
                case 'medium':
                default:
                    size = 'IconSizeMedium';
            }

            let {primaryColor, secondaryColor, secondaryOpacity, color} = props;
            const duoToneStyle: CSSProperties = {};

            if (primaryColor) duoToneStyle['--fa-primary-color'] = primaryColor;
            if (secondaryColor) duoToneStyle['--fa-secondary-color'] = secondaryColor;
            if (secondaryOpacity) duoToneStyle['--fa-secondary-opacity'] = secondaryOpacity;

            const buttonClass = [
                props.type.style,
                size,
                props.className,
                props.onClick ? '' : 'nofocus',
                isApple() ? 'ios' : ''
            ].join(' ');

            const iClass = [
                props.pressed ? 'pressed' : '',
                props.onClick ? '' : 'nofocus',
                props.disabled ? 'disabled' : ''
            ].join(' ');

            const iconStyle = !(primaryColor || secondaryColor) && color ? {color} : {};

            const textStyle: CSSProperties = props.textColor ? {color: props.textColor} : {};
            if (props.labelOnLeft) textStyle.paddingLeft = 0;

            const onClick: MouseEventHandler<HTMLElement> = (e) => {
                e.persist();

                if (props.onClick && !props.disabled && onClicked) onClicked(e);
            };

            const onKeyDown: KeyboardEventHandler<HTMLElement> = (e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.persist();

                    if (props.onClick &&!props.disabled && onClicked) onClicked(e);
                }
            }

            let theIcon: ReactElement;

            if (props.flagged) {
                theIcon = <motion.span className={`${iClass} fa-layers fa-fw`}
                                       onClick={props.ignoreTextClick ? undefined : onClick}
                                       onKeyDown={props.ignoreTextClick ? undefined : onKeyDown}
                                       whileHover={props.disabled || !props.onClick ? {} : {scale: 1.1}}
                                       whileTap={props.disabled || !props.onClick ? {} : {scale: 0.9}}
                >
                    <FontAwesomeIcon key="fai"
                                     icon={props.type.reactIcon/* || props.type.icon*/}
                                     transform={props.type.dataFaTransform}
                                     mask={props.type.dataFaMask}
                                     shake={(props.shake || props.type.animate === 'shake') ? true : undefined}
                                     spin={(props.spin || props.type.animate === 'spin') ? true : undefined}
                                     beat={(props.beat || props.type.animate === 'beat') ? true : undefined}
                                     beatFade={(props.beatFade || (props.type.animate === 'beatFade')) ? true : undefined}
                                     fade={(props.fade || props.type.animate === 'fade') ? true : undefined}
                                     rotation={props.type.rotation as RotateProp}
                    />
                    <span className="fa-layers-text icon-button--flagged"
                          style={{backgroundColor: props.flagColor || 'tomato'}}>!</span>
                </motion.span>;
            }
            else if (!!props.count && props.count >= 0) {
                theIcon = <motion.span className={`${iClass} fa-layers fa-fw`}
                                       onClick={props.ignoreTextClick ? undefined : onClick}
                                       onKeyDown={props.ignoreTextClick ? undefined : onKeyDown}
                                       whileHover={props.disabled || !props.onClick ? {} : {scale: 1.1}}
                                       whileTap={props.disabled || !props.onClick ? {} : {scale: 0.9}}
                >
                    <FontAwesomeIcon key="fai"
                                     icon={props.type.reactIcon/* || props.type.icon*/}
                                     transform={props.type.dataFaTransform}
                                     mask={props.type.dataFaMask}
                                     shake={(props.shake || props.type.animate === 'shake') ? true : undefined}
                                     spin={(props.spin || props.type.animate === 'spin') ? true : undefined}
                                     beat={(props.beat || props.type.animate === 'beat') ? true : undefined}
                                     beatFade={(props.beatFade || props.type.animate === 'beatFade') ? true : undefined}
                                     fade={(props.fade || props.type.animate === 'fade') ? true : undefined}
                                     rotation={props.type.rotation}
                    />
                    <span className="fa-layers-counter"
                          style={{
                              fontSize: '2em',
                              backgroundColor: props.countBackground
                          }}>{props.count}</span>
                </motion.span>;
            }
            else if (props.onClick) {
                const content = (
                    <motion.i className='icon-button__the-icon'
                              onClick={props.ignoreTextClick ? undefined : onClick}
                              onKeyDown={props.ignoreTextClick ? undefined : onKeyDown}
                              whileHover={props.disabled || !props.onClick ? {} : {scale: 1.1}}
                              whileTap={props.disabled || !props.onClick ? {} : {scale: 0.9}}
                              tabIndex={-1}
                    >
                        <FontAwesomeIcon key="fai"
                                         style={duoToneStyle}
                                         className={iClass}
                                         icon={props.type.reactIcon/* || props.type.icon*/}
                                         transform={props.type.dataFaTransform}
                                         mask={props.type.dataFaMask}
                                         shake={(props.shake || props.type.animate === 'shake') ? true : undefined}
                                         spin={(props.spin || props.type.animate === 'spin') ? true : undefined}
                                         beat={(props.beat || props.type.animate === 'beat') ? true : undefined}
                                         beatFade={(props.beatFade || props.type.animate === 'beatFade') ? true : undefined}
                                         fade={(props.fade || props.type.animate === 'fade') ? true : undefined}
                                         rotation={props.type.rotation}
                        />
                    </motion.i>
                );

                theIcon = !!title && !props.disabled ? <Tooltip title={title}>{content}</Tooltip>
                                                     : content;
            }
            else {
                const content = <FontAwesomeIcon key="fai"
                                                 className={iClass}
                                                 icon={props.type.reactIcon/* || props.type.icon*/}
                                                 transform={props.type.dataFaTransform}
                                                 mask={props.type.dataFaMask}
                                                 shake={(props.shake || props.type.animate === 'shake') ? true : undefined}
                                                 spin={(props.spin || props.type.animate === 'spin') ? true : undefined}
                                                 beat={(props.beat || props.type.animate === 'beat') ? true : undefined}
                                                 beatFade={(props.beatFade || props.type.animate === 'beatFade') ? true : undefined}
                                                 fade={(props.fade || props.type.animate === 'fade') ? true : undefined}
                                                 rotation={props.type.rotation}
                                                 style={props.svgWidth ? {
                                                     width: `${props.svgWidth}em`,
                                                     alignSelf: 'center',
                                                     ...duoToneStyle
                                                 } : duoToneStyle}
                />;

                theIcon = !!title && !props.disabled ? <Tooltip title={title}>{content}</Tooltip>
                                                     : content;
            }

            const theLabel = (typeof (props.label) === 'string' && props.label) && (
                <div key="label"
                     style={textStyle}
                     tabIndex={-1}
                     className={`icon-button__label ${props.labelBold ? 'icon-button__label--bold' : ''}`}>{props.label}</div>
            );

            // Knowing the ID of the button only really matters if we start doing UI automation.
            // if (!props.id && props.onClick && !props.hidden) console.debug('No ID passed for IconButton', props.type.style, props.label);

            const pieces = props.labelOnLeft ? <>{theLabel}{theIcon}</> : <>{theIcon}{theLabel}</>;

            let key = props.id || (typeof (props.label) !== 'object' ? props.label : props.type.style);
            if (typeof (key) === 'object') console.warn('Using an object as a key', key);

            const ariaLabel = props.alt || typeof(props.label) === 'string' ? props.label as string : 'button';

            if (props.hidden) return null;
            else return <div key={key}
                             id={props.id}
                             className={`icon-button ${props.type.style}`}
                             style={props.style}
                             onMouseDown={props.ignoreTextClick ? undefined : onClick}
                             onKeyDown={props.ignoreTextClick ? undefined : onKeyDown}
                             role='button'
                             aria-label={ariaLabel}
            >
                {props.onClick && <button
					ref={ref}
					autoFocus={props.autoFocus}
					id={props.id ? `${props.id}__button` : undefined}
					type={props.isSubmit ? "submit" : "button"}
					disabled={props.disabled}
					style={iconStyle}
					tabIndex={props.tabIndex}
					className={buttonClass}
					data-private={props.encrypted}
					data-dd-privacy={props.encrypted ? "mask" : "allow"}
				>
                    {pieces}
				</button>
                }
                {!props.onClick && <div className={`icon-button--no-click ${buttonClass}`}
				                        style={iconStyle}
				                        data-private={props.encrypted}
                                        tabIndex={props.tabIndex}
				>
                    {pieces}
				</div>
                }

                {(typeof (props.label) !== 'string' && props.label) && <div
					style={textStyle}
					data-private={props.encrypted}
					data-dd-privacy={props.encrypted ? "mask" : "allow"}
					className={`icon-button__label ${props.labelBold ? 'icon-button__label--bold' : ''}`}>{props.label}</div>}
            </div>;
        }
    ), {
        // Error boundary props
        fallbackRender: ({error}) => <FontAwesomeIcon icon={Icons.ERROR.reactIcon}
                                                      title={error.toString()}
                                                      color='maroon'
                                                      style={{padding: '3px', margin: '3px 0 0 0'}}
        />,
        onError(error, info) {
            console.error(error);
            console.error(info);
        }
    }
);

export default debounceRender(IconButton, 100);
