import {IconProp, RotateProp} from "@fortawesome/fontawesome-svg-core";

import {
    faArrowAltCircleDown as faArrowAltCircleDownRegular,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowAltCircleUp,
    faCircle as faCircleRegular,
    faDotCircle,
    faExchange,
    faFaceAngry,
    faFaceSmile,
    faFaceVomit,
    faFlag as faFlagRegular,
    faHeart,
    faParkingCircle,
    faPartyHorn,
    faPoo,
    faPowerOff
} from '@fortawesome/pro-regular-svg-icons';

import {
    faArrowAltCircleDown as faArrowAltCircleDownSolid,
    faArrowAltCircleUp as faArrowAltCircleUpSolid,
    faCircle as faCircleSolid,
    faCircleQuestion as faCircleQuestionSolid,
    faCloud,
    faCloudDownload as faCloudDownloadSolid,
    faCloudUpload as faCloudUploadSolid,
    faFilter as faFilterSolid,
    faHandHoldingSeedling,
    faHandHoldingUsd,
    faInboxFull,
    faMinusCircle,
    faSpinner,
    faStar as faStarSolid,
    faStop,
    faTimesCircle,
    faTimesCircle as faTimesCircleSolid,
    faToggleOff,
    faToggleOn,
    faTrashUndo
} from "@fortawesome/pro-solid-svg-icons";

import {
    faAnalytics as faAnalyticsDuotone,
    faBrowser as faBrowserDuotone,
    faBugSlash,
    faCalendarStar as faCalendarStarDuotone,
    faCirclePlus as faCirclePlusDuotone,
    faCreditCard as faCreditCardDuotone,
    faDollarSign,
    faDumpsterFire,
    faEraser,
    faEye,
    faEyeSlash,
    faFileEdit,
    faFileInvoiceDollar as faFileInvoiceDollarDuotone,
    faFiles as faFilesDuotone,
    faFlag,
    faFlagCheckered,
    faFolderBookmark,
    faFolderClosed,
    faFolderOpen,
    faFolders as faFoldersDuotone,
    faInfoCircle as faInfoCircleDuotone,
    faKey,
    faLock,
    faMoneyBill as faMoneyBillDuotone,
    faPodcast,
    faSkullCrossbones,
    faSparkles as faSparklesDuotone,
    faSquare as faSquareDuotone,
    faSquareA,
    faSquareP,
    faStamp,
    faStar as faStarDuotone,
    faSyncAlt as faSyncAltDuotone,
    faTableColumns as faTableColumnsDuotone,
    faTachometer,
    faTh as faThDuotone,
    faTimesCircle as faTimesCircleDuotone,
    faUndo,
    faUniversity as faUniversityDuo,
    faUnlock,
    faVault as faVaultDuotone,
    faWindow,
} from '@fortawesome/pro-duotone-svg-icons';

import {faCcAmex, faCcDinersClub, faCcDiscover, faCcMastercard, faCcVisa, faChrome, faEdge, faFacebook, faFirefox, faGoogle, faIntercom, faSafari, faTwitter} from '@fortawesome/free-brands-svg-icons';

import {
    faAd,
    faAddressBook,
    faAddressCard,
    faAnalytics,
    faAngleDoubleDown,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDoubleUp,
    faArrowDown,
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowLeft,
    faArrowRight,
    faArrowsRotate,
    faArrowToBottom,
    faArrowToTop,
    faArrowUp,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faBalanceScale,
    faBallotCheck,
    faBan,
    faBars,
    faBlog,
    faBookReader,
    faBoxingGlove,
    faBoxUsd,
    faBrowser,
    faBuilding,
    faCalculator,
    faCalendar,
    faCalendarAlt,
    faCalendarCirclePlus,
    faCalendarDay,
    faCalendarRange,
    faCalendarWeek,
    faChalkboardTeacher,
    faChartBar,
    faChartNetwork,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleInfo,
    faCirclePlus,
    faCircleQuestion,
    faClipboard,
    faClock,
    faClone,
    faCloudDownload,
    faCloudUpload,
    faCodeMerge,
    faCog,
    faCommand,
    faComment,
    faCommentEdit,
    faCommentPlus,
    faComments,
    faCommentSmile,
    faConstruction,
    faCopy,
    faCreditCard,
    faCut,
    faDonate,
    faDownload,
    faEdit,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faFaceExhaling,
    faFaceFrownSlight,
    faFaceTongueMoney,
    faFax,
    faFile,
    faFileAlt,
    faFileAudio,
    faFileExcel,
    faFileImage,
    faFileImport,
    faFileInvoice,
    faFileInvoiceDollar,
    faFilePdf,
    faFilePowerpoint,
    faFiles,
    faFileSearch,
    faFileSignature,
    faFileUser,
    faFileVideo,
    faFileWord,
    faFilter,
    faFilterList,
    faFolders,
    faGavel,
    faGraduationCap,
    faHandshake,
    faHeadphones,
    faHome,
    faIdCard,
    faIdCardAlt,
    faInfinity,
    faLifeRing,
    faLink,
    faList,
    faLockKeyhole,
    faLongArrowDown,
    faLongArrowUp,
    faMailbox,
    faMapMarkerAlt,
    faMapMarkerCheck,
    faMapSigns,
    faMegaphone,
    faMessage,
    faMessages,
    faMobileAndroid,
    faMoneyBillAlt,
    faMoneyCheckAlt,
    faMoneyCheckDollar,
    faMoneyCheckEditAlt,
    faNewspaper,
    faPaperclip,
    faPaperPlane,
    faPassport,
    faPaste,
    faPauseCircle,
    faPeace,
    faPencil,
    faPenSquare,
    faPercentage,
    faPersonSign,
    faPersonToDoor,
    faPhone,
    faPiggyBank,
    faPlayCircle,
    faPlug,
    faPresentation,
    faPrint,
    faRemoveFormat,
    faRepeat,
    faRetweet,
    faRunning,
    faSackDollar,
    faSearch,
    faSearchLocation,
    faSearchMinus,
    faSearchPlus,
    faShareAltSquare,
    faShareNodes,
    faSignature,
    faSortAlphaDown,
    faSortAlphaDownAlt,
    faSortAmountDownAlt,
    faSortAmountUpAlt,
    faSortNumericDown,
    faSortNumericDownAlt,
    faSquare,
    faSquareEllipsis,
    faEllipsisVertical,
    faSquareSliders,
    faStar,
    faStopwatch,
    faSuitcase,
    faSyncAlt,
    faTable,
    faTag,
    faTags,
    faTasks,
    faTh,
    faThumbsDown,
    faThumbsUp,
    faTimelineArrow,
    faTimes,
    faTrashAlt,
    faTrophy,
    faTruckMedical,
    faUndoAlt,
    faUniversity,
    faUnlockKeyhole,
    faUsdCircle,
    faUsdSquare,
    faUser,
    faUserCircle,
    faUserClock,
    faUserCog,
    faUserCowboy,
    faUserNinja,
    faUsers,
    faUsersClass,
    faVault,
    faVialCircleCheck,
    faWalking,
    faWarning,
    faWineGlass
} from "@fortawesome/pro-light-svg-icons";

export enum IconSize {
    normal = 'normal',
    small = 'small',
    smaller = 'smaller',
    medium = 'medium',
    large = 'large',
    xlarge = 'xlarge'
}

export type IconSizes = keyof typeof IconSize;

export interface IconType {
    style: string;
    icon: string;
    tags: string[];
    reactIcon: IconProp;
    color?: string;
    duotone?: boolean;
    level?: number;
    faPrimaryColor?: string;
    faSecondaryColor?: string;
    dataFaTransform?: string;
    dataFaMask?: IconProp;
    animate?: 'spin' | 'shake' | 'beat' | 'beatFade' | 'fade';
    rotation?: RotateProp;
}

class Icons {
    public static IconSizeNormal = IconSize.normal;
    public static IconSizeSmall = IconSize.small;
    public static IconSizeSmaller = IconSize.smaller;
    public static IconSizeMedium = IconSize.normal;
    public static IconSizeLarge = IconSize.large;
    public static IconSizeXLarge = IconSize.xlarge;

    public static AMBULANCE: IconType = {
        style: 'Ambulance',
        tags: ['error', 'ambulance', 'emergency', 'medical'],
        icon: 'fa-light fa-truck-medical',
        reactIcon: faTruckMedical
    };
    public static ATTACHMENT: IconType = {
        style: 'Attachment',
        tags: ['paperclip', 'attachment'],
        icon: 'fa-light fa-paperclip',
        reactIcon: faPaperclip
    };
    public static AD: IconType = {style: 'Ad', tags: ['ad', 'marketing'], icon: 'fa-light fa-ad', reactIcon: faAd};
    public static ADD: IconType = {style: 'Add', tags: ['add', 'plus'], icon: 'fa-light fa-circle-plus', reactIcon: faCirclePlus};
    public static ADD_SOLID: IconType = {
        style: 'AddSolid',
        tags: ['add', 'plus'],
        icon: 'fa-duo fa-circle-plus',
        reactIcon: faCirclePlusDuotone
    };
    public static ADDRESS: IconType = {
        style: 'Address',
        tags: ['address'],
        icon: 'fa-light fa-map-marker-alt',
        reactIcon: faMapMarkerAlt
    };
    public static ADDRESS_BOOK: IconType = {
        style: 'AddressBook',
        tags: ['address', 'book', 'contacts'],
        icon: 'fa-light fa-address-book',
        reactIcon: faAddressBook
    };
    public static ADDRESS_CARD: IconType = {
        style: 'AddressCard',
        tags: ['address', 'card', 'contact'],
        icon: 'fa-light fa-address-card',
        reactIcon: faAddressCard
    };
    public static ADDRESS_CURRENT: IconType = {
        style: 'AddressCurrent',
        tags: ['map', 'marker', 'place'],
        icon: 'fa-light fa-map-marker-check',
        reactIcon: faMapMarkerCheck
    };
    public static ARROW_LEFT: IconType = {
        style: 'ArrowLeft',
        tags: ['arrow', 'left'],
        icon: 'fa-light fa-arrow-left',
        reactIcon: faArrowLeft
    };
    public static ARROW_RIGHT: IconType = {
        style: 'ArrowRight',
        tags: ['arrow', 'right'],
        icon: 'fa-light fa-arrow-right',
        reactIcon: faArrowRight
    };
    public static ARROW_DOWN: IconType = {
        style: 'ArrowDown',
        tags: ['arrow', 'down'],
        icon: 'fa-light fa-arrow-down',
        reactIcon: faArrowDown
    };
    public static ARROW_UP: IconType = {
        style: 'ArrowUp',
        tags: ['arrow', 'up'],
        icon: 'fa-light fa-arrow-up',
        reactIcon: faArrowUp
    };
    public static ARROW_TO_BOTTOM: IconType = {
        style: 'ArrowToBottom',
        tags: ['arrow', 'bottom'],
        icon: 'fa-light fa-arrow-to-bottom',
        reactIcon: faArrowToBottom
    };
    public static ARROW_TO_TOP: IconType = {
        style: 'ArrowToTop',
        tags: ['arrow', 'top'],
        icon: 'fa-light fa-arrow-to-top',
        reactIcon: faArrowToTop
    };
    public static ANALYTICS: IconType = {
        style: 'Analytics',
        tags: ['analytics', 'chart', 'graph', 'report'],
        icon: 'fa-light fa-analytics',
        reactIcon: faAnalytics
    };
    public static ANALYTICSD: IconType = {
        style: 'AnalyticsD',
        tags: ['analytics', 'chart', 'graph', 'report'],
        icon: 'fa-duotone fa-analytics',
        reactIcon: faAnalyticsDuotone
    };
    public static BALANCE_ADJUSTMENT: IconType = {
        style: 'BalanceAdjustment',
        tags: ['circle', 'exclamation'], icon: 'fa-light fa-exclamation-circle',
        reactIcon: faExclamationCircle
    };
    public static BANK: IconType = {
        style: 'Bank',
        tags: ['university', 'college', 'education'],
        icon: 'fa-light fa-university',
        reactIcon: faUniversity
    };
    public static BANK_DUO: IconType = {
        style: 'Bank',
        tags: ['university', 'college', 'education'],
        icon: 'fa-duotone fa-solid fa-university',
        reactIcon: faUniversityDuo
    };
    public static BLANK: IconType = {style: 'Blank', tags: ['square', 'blank'], icon: 'fa-light fa-square', reactIcon: faSquare};
    public static BLOG: IconType = {style: 'Blog', tags: ['blog', 'marketing'], icon: 'fa-light fa-blog', reactIcon: faBlog};
    public static BOXING_GLOVE: IconType = {
        style: 'BoxingGlove',
        tags: ['boxing', 'glove', 'contingency'],
        icon: 'fa-light fa-boxing-glove',
        reactIcon: faBoxingGlove,
    };
    public static BULLETIN: IconType = {
        style: 'Bulletin',
        tags: ['bulletin', 'marketing'],
        icon: 'fa-light fa-megaphone',
        reactIcon: faMegaphone
    };
    public static ACTIVITY: IconType = {
        style: 'Activity',
        tags: ['activity', 'running'],
        icon: 'fa-light fa-running',
        reactIcon: faRunning
    };
    public static ADMIN: IconType = {
        style: 'Admin',
        tags: ['user', 'cog', 'configure'],
        icon: 'fa-light fa-user-cog',
        reactIcon: faUserCog
    };
    public static BUSINESS: IconType = {
        style: 'Business',
        tags: ['business', 'building', 'office'],
        icon: 'fa-light fa-building',
        reactIcon: faBuilding
    };
    public static CALCULATOR: IconType = {
        style: 'Calculator',
        tags: ['calculator', 'math', 'addition'],
        icon: 'fa-light fa-calculator',
        reactIcon: faCalculator
    };
    public static CALENDAR: IconType = {
        style: 'Calendar',
        tags: ['calendar', 'time'],
        icon: 'fa-light fa-calendar-alt',
        reactIcon: faCalendarAlt
    };
    public static CALENDAR_ALL: IconType = {
        style: 'CalendarAll',
        tags: ['time', 'infinity'], icon: 'fa-solid fa-infinity',
        dataFaTransform: 'shrink-3',
        reactIcon: faInfinity
    };
    public static CALENDAR_EDIT: IconType = {
        style: 'CalendarEdit',
        tags: ['calendar', 'time', 'range'], icon: 'fa-duotone fa-calendar-range',
        reactIcon: faCalendarRange
    };
    public static CALENDAR_STAR: IconType = {
        style: 'CalendarStar',
        tags: ['calendar', 'start', 'time', 'star'], icon: 'fa-duotone fa-calendar-star',
        reactIcon: faCalendarStarDuotone
    };
    public static CALENDAR_TOMORROW: IconType = {
        style: 'CalendarTomorrow',
        tags: ['calendar', 'time', 'tomorrow', 'circle', 'plus'], icon: 'fa-light fa-calendar-circle-plus',
        reactIcon: faCalendarCirclePlus
    };
    public static CALENDAR_DAY: IconType = {
        style: 'CalendarDay',
        tags: ['calendar', 'time', 'day'],
        icon: 'fa-duotone fa-calendar-day',
        reactIcon: faCalendarDay
    };
    public static CALENDAR_WEEK: IconType = {
        style: 'CalendarWeek',
        tags: ['calendar', 'time', 'week'], icon: 'fa-duotone fa-calendar-week',
        reactIcon: faCalendarWeek
    };
    public static CALENDAR_MONTH: IconType = {
        style: 'CalendarMonth',
        tags: ['calendar', 'time', 'month'], icon: 'fa-duotone fa-calendar-alt',
        reactIcon: faCalendarAlt
    };
    public static CANCEL: IconType = {
        style: 'Cancel',
        tags: ['times', 'circle'],
        icon: 'fa-solid fa-times-circle',
        reactIcon: faTimesCircle
    };
    public static CASE: IconType = {
        style: 'Case',
        tags: ['suitcase', 'case', 'matter'],
        icon: 'fa-light fa-suitcase',
        reactIcon: faSuitcase
    };
    public static CHARITY: IconType = {
        style: 'Charity',
        tags: ['donate', 'money', 'donation', 'give'],
        icon: 'fa-light fa-donate',
        reactIcon: faDonate
    };
    public static CHART: IconType = {
        style: 'Chart',
        tags: ['chart', 'bar', 'graph'],
        icon: 'fa-light fa-chart-bar',
        reactIcon: faChartBar
    };
    public static CHECKED: IconType = {
        style: 'Checked',
        tags: ['check', 'square', 'done', 'finished', 'complete'],
        icon: 'fa-light fa-check-square',
        reactIcon: faCheckSquare
    };
    public static CHECKEDSOLID: IconType = {
        style: 'Checked',
        tags: ['check', 'square', 'done', 'finished', 'complete'],
        icon: 'fa-duo fa-check-square',
        reactIcon: faCheckSquare
    };
    public static CHECKCIRCLE: IconType = {
        style: 'CheckCircle',
        tags: ['check', 'circle', 'done', 'finished', 'complete'],
        icon: 'fa-brand fa-check-circle',
        reactIcon: faCheckCircle
    };
    public static CHECKMARK: IconType = {
        style: 'Checkmark',
        tags: ['check', 'checkmark', 'done', 'finished', 'complete'],
        icon: 'fa-light fa-check',
        reactIcon: faCheck
    };
    public static CHEVRON_UP: IconType = {
        style: 'ChevronUp',
        tags: ['chevron', 'up'],
        icon: 'fa-light fa-chevron-up',
        reactIcon: faChevronUp
    };
    public static CHEVRON_DOWN: IconType = {
        style: 'ChevronUp',
        tags: ['chevron', 'down'],
        icon: 'fa-light fa-chevron-down',
        reactIcon: faChevronDown
    };
    public static CHOICES: IconType = {
        style: 'Choices',
        tags: ['ballot', 'check'],
        icon: 'fa-light ballot-check',
        reactIcon: faBallotCheck
    };
    public static CHROME: IconType = {
        style: 'Chrome',
        tags: ['browser', 'chrome', 'website'],
        icon: 'fa-brand fa-chrome',
        reactIcon: faChrome
    };
    public static CIRCLE: IconType = {
        style: 'Circle',
        tags: ['circle'],
        icon: 'fa-light fa-circle',
        reactIcon: faCircle
    };
    public static CIRCLE_CHECKED: IconType = {
        style: 'CircleChecked',
        tags: ['circle', 'check'],
        icon: 'fa-light fa-circle',
        reactIcon: faCircleCheck
    };
    public static CIRCLEFILLED: IconType = {
        style: 'CircleFilled',
        tags: ['circle'],
        icon: 'fa-solid fa-circle',
        reactIcon: faCircleSolid
    };
    public static CIRCLEUNFILLED: IconType = {
        style: 'CircleUnfilled',
        tags: ['circle', 'incomplete'],
        icon: 'fa-regular fa-circle',
        reactIcon: faCircleRegular
    };
    public static CIRCLEDOT: IconType = {
        style: 'CircleDot',
        tags: ['circle', 'dot'],
        icon: 'fa-regular fa-dot-circle',
        reactIcon: faDotCircle
    };
    public static CIRCLELEFT: IconType = {
        style: 'CircleLeft',
        tags: ['circle', 'left'], icon: 'fa-regular fa-arrow-alt-circle-left',
        reactIcon: faArrowAltCircleLeft
    };
    public static CIRCLERIGHT: IconType = {
        style: 'CircleRight',
        tags: ['circle', 'rightt'], icon: 'fa-regular fa-arrow-alt-circle-right',
        reactIcon: faArrowAltCircleRight
    };
    public static CIRCLEDOWN: IconType = {
        style: 'CircleDown',
        tags: ['circle', 'down'], icon: 'fa-regular fa-arrow-alt-circle-down',
        reactIcon: faArrowAltCircleDownRegular
    };
    public static CIRCLEDOWNSOLID: IconType = {
        style: 'CircleDownSolid',
        tags: [], icon: 'fa-solid fa-arrow-alt-circle-up',
        reactIcon: faArrowAltCircleDownSolid
    };
    public static CIRCLEUP: IconType = {
        style: 'CircleUp',
        tags: ['circle', 'up'], icon: 'fa-regular fa-arrow-alt-circle-up',
        reactIcon: faArrowAltCircleUp
    };
    public static CIRCLEUPSOLID: IconType = {
        style: 'CircleUpSolid',
        tags: ['circle', 'up'], icon: 'fa-solid fa-arrow-alt-circle-up',
        reactIcon: faArrowAltCircleUpSolid
    };
    public static CLIENT: IconType = {
        style: 'Client',
        tags: ['client', 'user', 'clock', 'billable'],
        icon: 'fa-light fa-user-clock',
        reactIcon: faUserClock
    };
    public static CLONE: IconType = {
        style: 'Clone',
        tags: ['clone', 'duplicate', 'copy'],
        icon: 'fa-light fa-clone',
        reactIcon: faClone
    };

    public static CC_AMEX: IconType = {
        style: 'CcAmex',
        tags: ['bank', 'credit', 'card', 'amex', 'american', 'express'],
        icon: 'fa-brands fa-cc-amex',
        reactIcon: faCcAmex
    };

    public static CC_DINERS_CLUB: IconType = {
        style: 'CcDinersClub',
        tags: ['bank', 'credit', 'card', 'diners', 'club'],
        icon: 'fa-brands fa-cc-diners-club',
        reactIcon: faCcDinersClub
    };

    public static CC_DISCOVER: IconType = {
        style: 'CcDiscover',
        tags: ['bank', 'credit', 'card', 'discover'],
        icon: 'fa-brands fa-cc-discover',
        reactIcon: faCcDiscover
    };

    public static CC_MASTERCARD: IconType = {
        style: 'CcMastercard',
        tags: ['bank', 'credit', 'card', 'mastercard'],
        icon: 'fa-brands fa-cc-mastercard',
        reactIcon: faCcMastercard
    };

    public static CC_VISA: IconType = {
        style: 'CcVisa',
        tags: ['bank', 'credit', 'card', 'visa'],
        icon: 'fa-brands fa-cc-visa',
        reactIcon: faCcVisa
    };

    public static SMILE: IconType = {
        style: 'Smile',
        tags: ['smile', 'happy', 'emoji'],
        icon: 'fa-light fa-face-smile',
        reactIcon: faFaceSmile,
        color: 'blue',
        level: 1
    }
    public static SPARKLES: IconType = {
        style: 'Sparkles',
        tags: ['sparkles', 'new', 'star'],
        icon: 'fa-duotone fa-sparkles',
        reactIcon: faSparklesDuotone
    };
    public static SPLITVIEW: IconType = {
        style: 'SplitView',
        tags: ['split', 'view', 'table', 'columns'],
        icon: 'fa-duotone fa-table-columns',
        reactIcon: faTableColumnsDuotone
    };
    public static COMMENT: IconType = {
        style: 'Comment',
        tags: [],
        icon: 'fa-light fa-comment',
        reactIcon: faComment
    };
    public static COMMENT_ADD: IconType = {
        style: 'CommentsAdd',
        tags: ['comment', 'add', 'discussion'],
        icon: 'fa-light fa-comment-plus',
        reactIcon: faCommentPlus
    };
    public static COMMENTS: IconType = {
        style: 'Comments',
        tags: ['comments', 'discussion'],
        icon: 'fa-light fa-comments',
        reactIcon: faComments
    };
    public static CONVERT: IconType = {
        style: 'Convert',
        tags: ['convert', 'process', 'retweet'],
        icon: 'fa-light fa-retweet',
        reactIcon: faRetweet
    };
    public static CLIPBOARD: IconType = {
        style: 'Clipboard',
        tags: ['clipboard'],
        icon: 'fa-light fa-clipboard',
        reactIcon: faClipboard
    };
    public static CLOCK: IconType = {style: 'Clock', tags: ['clock', 'time'], icon: 'fa-light fa-clock', reactIcon: faClock};
    public static CLOSE: IconType = {style: 'Close', tags: ['close', 'cancel'], icon: 'fa-light fa-times', reactIcon: faTimes};
    public static CLOUD: IconType = {
        style: 'Cloud',
        tags: ['cloud', 'internet', 'web'],
        icon: 'fa-solid fa-cloud',
        reactIcon: faCloud
    };
    public static COMPRESS: IconType = {
        style: 'Compress',
        tags: ['arrow', 'compress', 'minimize'],
        icon: 'fa-light fa-arrow-down-left-and-arrow-up-right-to-center',
        reactIcon: faArrowDownLeftAndArrowUpRightToCenter
    };
    public static CONFIG: IconType = {
        style: 'Config',
        tags: ['cog', 'process', 'work'],
        icon: 'fa-light fa-cog',
        reactIcon: faCog
    };
    public static CONFLICT: IconType = {
        style: 'Conflict',
        tags: ['triangle', 'danger', 'warning', 'conflict'], icon: 'fa-light fa-exclamation-triangle',
        reactIcon: faExclamationTriangle
    };
    public static CONNECTED: IconType = {
        style: 'Connected',
        tags: ['plug', 'connect'],
        icon: 'fa-light fa-plug',
        reactIcon: faPlug
    };
    public static CONTACT: IconType = {style: 'Contact', tags: ['user', 'person'], icon: 'fa-light fa-user', reactIcon: faUser};
    public static COMMAND: IconType = {style: 'Command', tags: ['command', 'alt'], icon: 'fa-light fa-command', reactIcon: faCommand};
    public static COPY: IconType = {style: 'Copy', tags: ['copy'], icon: 'fa-light fa-copy', reactIcon: faCopy};
    public static CUT: IconType = {style: 'Cut', tags: ['cut'], icon: 'fa-light fa-cut', reactIcon: faCut};
    public static PASTE: IconType = {style: 'Paste', tags: ['paste'], icon: 'fa-light fa-paste', reactIcon: faPaste};

    public static CASE_OPEN: IconType = {
        style: 'CaseOpen',
        tags: ['case', 'open'],
        icon: 'fa-duotone fa-folder-open',
        dataFaMask: ['fas', 'o'],
        reactIcon: faFolderOpen
    };
    public static CASE_CLOSED: IconType = {
        style: 'CaseClosed',
        tags: ['case', 'closed', 'folder'],
        icon: 'fa-duotone fa-folder-closed',
        reactIcon: faFolderClosed
    };
    public static CASE_WITHDRAWN: IconType = {
        style: 'CaseWithdrawn',
        tags: ['case', 'withdraw', 'withdrawn', 'folder'], icon: 'fa-duotone fa-folder-closed',
        reactIcon: faFolderClosed
    };
    public static CASE_ON_HOLD: IconType = {
        style: 'CaseOnHold',
        tags: ['case', 'hold', 'folder'], icon: 'fa-duotone fa-folder-bookmark',
        reactIcon: faFolderBookmark
    };
    public static CASE_PENDING: IconType = {
        style: 'CasePending',
        tags: ['case', 'pending', 'folder'], icon: 'fa-duotone fa-folder-bookmark',
        reactIcon: faFolderBookmark
    };
    public static DUMPSTER: IconType = {
        style: 'Dumpster',
        tags: ['dumpster', 'fire'], icon: 'fa-duotone fa-dumpster-fire',
        faPrimaryColor: 'maroon',
        faSecondaryColor: 'yellow',
        reactIcon: faDumpsterFire
    };
    public static COURT: IconType = {
        style: 'Court',
        tags: ['court', 'scale', 'balance', 'justice', 'equal'],
        icon: 'fa-light fa-balance-scale',
        reactIcon: faBalanceScale
    };

    public static CHECK: IconType = {
        style: 'Check',
        tags: ['check', 'money'],
        icon: 'fa-light fa-money-check-alt',
        reactIcon: faMoneyCheckAlt
    };

    public static PAYOUT: IconType = {
        style: 'Check',
        tags: ['check', 'money', 'pay', 'payout'],
        icon: 'fa-light fa-money-check-dollar',
        reactIcon: faMoneyCheckDollar
    };


    public static DEBUG: IconType = {
        style: 'Debug',
        tags: ['bug', 'debug'],
        icon: 'fa-duotone fa-bug-slash',
        reactIcon: faBugSlash
    };
    public static TEST: IconType = {
        style: 'Test',
        tags: ['circel', 'vial', 'test'],
        icon: 'fa-light fa-vial-circle-check',
        reactIcon: faVialCircleCheck
    };
    public static DELETE: IconType = {
        style: 'Delete',
        tags: ['trash', 'waste'],
        icon: 'fa-light fa-trash-alt',
        reactIcon: faTrashAlt
    };
    public static DELETE_WARNING: IconType = {
        style: 'DeleteWarning',
        tags: ['circle', 'minus', 'remove', 'delete'],
        icon: 'fa-solid fa-minus-circle',
        reactIcon: faMinusCircle
    };
    public static RETRY: IconType = {
        style: 'Retry',
        tags: ['circle', 'arrows', 'retry', 'repeat'],
        icon: 'fa-light fa-arrows-rotate',
        reactIcon: faArrowsRotate
    };
    public static RETRY_SPIN: IconType = {
        style: 'Repeat',
        tags: ['circle', 'arrows', 'retry', 'repeat'],
        icon: 'fa-light fa-arrows-rotate',
        reactIcon: faArrowsRotate,
        animate: 'spin'
    }
    public static RESTORE: IconType = {
        style: 'Restore',
        tags: ['trash', 'undo', 'restore'],
        icon: 'fa-solid fa-trash-undo',
        reactIcon: faTrashUndo
    };
    public static DOLLAR: IconType = {
        style: 'Dollar',
        tags: ['money', 'dollar', 'cash'],
        icon: 'fa-light fa-money-bill-alt',
        reactIcon: faMoneyBillAlt
    };
    public static DOLLAR_DUO: IconType = {
        style: 'Dollar',
        tags: ['money', 'dollar', 'cash'],
        icon: 'fa-duotone fa-solid fa-money-bill-alt',
        reactIcon: faMoneyBillDuotone
    };
    public static DOLLARSIGN: IconType = {
        style: 'DollarSign',
        tags: ['money', 'dollar', 'cash'],
        icon: 'fa-duotone fa-dollar-sign',
        reactIcon: faDollarSign
    };
    public static DOUBLEDOWN: IconType = {
        style: 'DoubleDown',
        tags: ['arrow', 'down'], icon: 'fa-light fa-angle-double-down',
        reactIcon: faAngleDoubleDown
    };
    public static DOUBLERIGHT: IconType = {
        style: 'DoubleRight',
        tags: ['arrow', 'right'], icon: 'fa-light fa-angle-double-right',
        reactIcon: faAngleDoubleRight
    };
    public static DOUBLELEFT: IconType = {
        style: 'DoubleLeft',
        tags: ['arrow', 'left'], icon: 'fa-light fa-angle-double-left',
        reactIcon: faAngleDoubleLeft
    };
    public static DOUBLEUP: IconType = {
        style: 'DoubleUp',
        tags: ['arrow', 'up'],
        icon: 'fa-light fa-angle-double-up',
        reactIcon: faAngleDoubleUp
    };
    public static DOWNLOAD: IconType = {
        style: 'Download',
        tags: ['download'],
        icon: 'fa-solid fa-cloud-download',
        reactIcon: faCloudDownloadSolid
    };
    public static DOWNLOAD_LIGHT: IconType = {
        style: 'DownloadLight',
        tags: ['cloud', 'download'], icon: 'fa-light fa-cloud-download',
        reactIcon: faCloudDownload
    };
    public static DOWNLOAD_ALT: IconType = {
        style: 'DownloadAlt',
        tags: ['cloud', 'download'],
        icon: 'fa-light fa-download-alt',
        reactIcon: faDownload
    };
    public static DRAFT: IconType = {
        style: 'Draft',
        tags: ['draft', 'pen', 'square'],
        icon: 'fa-light fa-pen-square',
        reactIcon: faPenSquare
    };
    public static UPLOAD: IconType = {
        style: 'Upload',
        tags: ['cloud', 'upload'],
        icon: 'fa-solid fa-cloud-upload',
        reactIcon: faCloudUploadSolid
    };
    public static EMAIL: IconType = {
        style: 'Email',
        tags: ['envelope', 'mail', 'email'],
        icon: 'fa-light fa-envelope',
        reactIcon: faEnvelope
    }
    public static EDGE: IconType = {style: 'Edge', tags: ['browser', 'edge'], icon: 'fa-brand fa-edge', reactIcon: faEdge};
    public static EDIT: IconType = {style: 'Edit', tags: ['edit', 'square'], icon: 'fa-light fa-edit', reactIcon: faEdit};
    public static ERROR: IconType = {
        style: 'Error',
        tags: ['triangle', 'error'],
        icon: 'fa-light fa-exclamation-triangle',
        reactIcon: faExclamationTriangle
    };
    public static ESTABLISHMENT: IconType = {
        style: 'Establishment',
        tags: ['establishment', 'restaurant', 'marketing', 'glass', 'wine'],
        icon: 'fa-light fa-wine-glass',
        reactIcon: faWineGlass
    };
    public static EVENT: IconType = {
        style: 'Event',
        tags: ['calendar', 'event'],
        icon: 'fa-light fa-calendar',
        reactIcon: faCalendar
    };
// public static EYE: IconType = {style: 'Eye', tags: ['eye','visible','view'], icon:'fa-light fa-eye', reactIcon: faEye};
    public static FILE: IconType = {
        style: 'File',
        tags: ['file'],
        icon: 'fa-light fa-file',
        reactIcon: faFile
    };
    public static FILES: IconType = {
        style: 'Files',
        tags: ['file', 'files'],
        icon: 'fa-light fa-files',
        reactIcon: faFiles
    };
    public static FILES_DUO: IconType = {
        style: 'Files',
        tags: ['file', 'files'],
        icon: 'fa-duotone fa-solid fa-files',
        reactIcon: faFilesDuotone
    };
    public static FILE_PDF: IconType = {
        style: 'FilePdf',
        tags: ['file', 'pdf', 'acrobat'],
        icon: 'fa-light file-pdf',
        reactIcon: faFilePdf
    };
    public static FILE_DOC: IconType = {
        style: 'FileWord',
        tags: ['file', 'word'],
        icon: 'fa-light file-word',
        reactIcon: faFileWord
    };
    public static FILE_EDIT: IconType = {
        style: 'FileEdit',
        tags: ['file', 'edit', 'text'],
        icon: 'fa-duotone fa-solid file-edit',
        reactIcon: faFileEdit
    };
    public static FILE_XLS: IconType = {
        style: 'FileExcel',
        tags: ['file', 'excel', 'spreadsheet', 'microsoft'],
        icon: 'fa-light file-excel',
        reactIcon: faFileExcel
    };
    public static FILE_PPT: IconType = {
        style: 'FilePowerpoint',
        tags: ['file', 'powerpoint', 'microsoft'],
        icon: 'fa-light file-powerpoint',
        reactIcon: faFilePowerpoint
    };
    public static FILE_IMG: IconType = {
        style: 'FileImage',
        tags: ['file', 'image'],
        icon: 'fa-light file-image',
        reactIcon: faFileImage
    };
    public static FILE_AUDIO: IconType = {
        style: 'FileAudio',
        tags: ['file', 'audio'],
        icon: 'fa-light file-audio',
        reactIcon: faFileAudio
    };
    public static FILE_VIDEO: IconType = {
        style: 'FileVideo',
        tags: ['file', 'video', 'movie'],
        icon: 'fa-light file-video',
        reactIcon: faFileVideo
    };
    public static FILE_TEXT: IconType = {style: 'FileText', tags: ['file'], icon: 'fa-light file-alt', reactIcon: faFileAlt};
    public static FILE_HTML: IconType = {
        style: 'FileHtml',
        tags: ['file', 'invoice', 'bill'],
        icon: 'fa-light file-invoice',
        reactIcon: faFileInvoice
    };
    public static FOLDERS: IconType = {
        style: 'Folders',
        tags: ['folder', 'folders'],
        icon: 'fa-light fa-folders',
        reactIcon: faFolders
    };
    public static FOLDERS_DUO: IconType = {
        style: 'Folders',
        tags: ['folder', 'folders'],
        icon: 'fa-duotone fa-solid fa-folders',
        reactIcon: faFoldersDuotone
    };
    public static ELLIPSES: IconType = {
        style: 'Ellipses',
        tags: ['dots', 'ellipses', 'ellipsis'],
        icon: 'fa-light fa-square-ellipsis',
        reactIcon: faSquareEllipsis
    };
    public static ELLIPSES_VERTICAL = {
        style: 'EllipsesVertical',
        tags: ['dots', 'ellipses', 'ellipsis'],
        icon: 'fa-light fa-ellipsis-vertical',
        reactIcon: faEllipsisVertical
    }
    public static ERASER: IconType = {
        style: 'Eraser',
        tags: ['eraser', 'clear'],
        icon: 'fa-duotone fa-solid fa-eraser',
        reactIcon: faEraser
    };
    public static EXPAND: IconType = {
        style: 'Expand',
        tags: ['arrow', 'expand', 'maximize'],
        icon: 'fa-light fa-arrow-up-right-and-arrow-down-left-from-center',
        reactIcon: faArrowUpRightAndArrowDownLeftFromCenter
    };
    public static EXPORT: IconType = {
        style: 'Export',
        tags: ['cloud', 'upload'],
        icon: 'fa-light fa-cloud-upload',
        reactIcon: faCloudUpload
    };
    public static FACE_LOST: IconType = {
        style: 'FaceLost',
        tags: ['face', 'lose', 'unhappy', 'lost', 'frown'],
        icon: 'fa-light fa-face-frown-slight',
        reactIcon: faFaceFrownSlight
    }
    public static FACE_REFUSED: IconType = {
        style: 'FaceRefused',
        tags: ['face', 'refuse', 'phew'],
        icon: 'fa-light fa-face-exhaling',
        reactIcon: faFaceExhaling
    }
    public static FACE_WON: IconType = {
        style: 'FaceWon',
        tags: ['face', 'won', 'happy', 'win'],
        icon: 'fa-light fa-face-tongue-money',
        reactIcon: faFaceTongueMoney
    }
    public static FACEBOOK: IconType = {
        style: 'FacebookI',
        tags: ['marketing', 'facebook'],
        icon: 'fa-brand fa-facebook',
        reactIcon: faFacebook
    };
    public static FAX: IconType = {style: 'Fax', tags: ['fax'], icon: 'fa-light fa-fax', reactIcon: faFax};
    public static FILTER: IconType = {
        style: 'Filter',
        tags: ['filter', 'funnel'],
        icon: 'fa-light fa-filter',
        reactIcon: faFilter
    };
    public static FILTER_FILLED: IconType = {
        style: 'FilterFilled',
        tags: ['filter', 'funnel'],
        icon: 'fa-solid fa-filter',
        reactIcon: faFilterSolid
    };
    public static FIREFOX: IconType = {
        style: 'Firefox',
        tags: ['browser', 'firefox'],
        icon: 'fa-brand fa-firefox',
        reactIcon: faFirefox
    };
    public static FLAG: IconType = {
        style: 'Flag',
        tags: ['flag', 'warning', 'error'],
        icon: 'fa-regular fa-flag',
        reactIcon: faFlagRegular
    };
    public static FLAG_FILLED: IconType = {
        style: 'FlagFilled',
        tags: ['flag', 'warning', 'error'],
        icon: 'fa-duotone fa-solid fa-flag',
        reactIcon: faFlag
    };
    public static FLAG_CHECKERED: IconType = {
        style: 'FlagCheckered',
        tags: ['flag', 'checkered', 'finished'], icon: 'fa-duotone fa-solid fa-flag-checkered',
        reactIcon: faFlagCheckered
    };
    public static ID: IconType = {
        style: 'Identification',
        tags: ['id', 'identification', 'license'],
        icon: 'fa-light fa-id-card',
        reactIcon: faIdCard
    };
    public static IMPORT: IconType = {
        style: 'Import',
        tags: ['cloud', 'import'],
        icon: 'fa-light fa-cloud-download',
        reactIcon: faCloudDownload
    };
    public static GAVEL: IconType = {
        style: 'Gavel',
        tags: ['gavel', 'court', 'justice', 'judge'],
        icon: 'fa-light fa-gavel',
        reactIcon: faGavel
    };
    public static GRID: IconType = {style: 'Grid', tags: ['table', 'header'], icon: 'fa-light fa-th', reactIcon: faTh};
    public static GOOGLE: IconType = {
        style: 'Google',
        tags: ['marketing', 'google', 'ads', 'browser', 'internet'],
        icon: 'fa-brand fa-google',
        reactIcon: faGoogle
    };
    public static HEADPHONES: IconType = {
        style: 'Headphones',
        tags: ['headphones', 'listen'],
        icon: 'fa-light fa-headphones',
        reactIcon: faHeadphones
    };
    public static HEART: IconType = {
        style: 'Heart',
        tags: ['heart', 'love', 'emoji'],
        icon: 'fa-light fa-heart',
        reactIcon: faHeart,
        color: 'red',
        level: 1
    };
    public static HANDSHAKE: IconType = {
        style: 'Handshake',
        tags: ['handshake'],
        icon: 'fa-light fa-handshake',
        reactIcon: faHandshake
    };
    public static HELP: IconType = {
        style: 'Help',
        tags: ['circle', 'info', 'question'],
        icon: 'fa-duotone fa-solid fa-info-circle',
        reactIcon: faInfoCircleDuotone
    };
    public static INFORMATION: IconType = {
        style: 'Information',
        tags: ['circle', 'info', 'question'],
        icon: 'fa-light fa-circle-info',
        reactIcon: faCircleInfo
    };
    public static HOME: IconType = {style: 'Home', tags: ['home', 'house'], icon: 'fa-light fa-home', reactIcon: faHome};
    public static HR: IconType = {
        style: 'Home',
        tags: ['card', 'id', 'identification'],
        icon: 'fa-light fa-id-card-alt',
        reactIcon: faIdCardAlt
    };
    public static INVOICE: IconType = {
        style: 'Invoice',
        tags: ['invoice', 'document', 'file', 'money', 'dollar', 'bill'], icon: 'fa-light fa-file-invoice-dollar',
        dataFaTransform: 'grow-3',
        reactIcon: faFileInvoiceDollar
    };
    public static INVOICES: IconType = {
        style: 'Invoices',
        tags: ['email', 'inbox', 'full'],
        icon: 'fa-solid fa-inbox-full',
        reactIcon: faInboxFull
    };
    public static ACCOUNTS_RECEIVABLE: IconType = {
        style: 'AccountsReceivable',
        tags: ['file', 'invoice', 'dollar', 'bill'],
        icon: 'fa-light fa-file-invoice-dollar',
        reactIcon: faFileInvoiceDollar
    };
    public static ACCOUNTS_RECEIVABLE_DUO: IconType = {
        style: 'AccountsReceivableDuo',
        tags: ['file', 'invoice', 'dollar', 'bill'],
        icon: 'fa-duotone fa-solid fa-file-invoice-dollar',
        reactIcon: faFileInvoiceDollarDuotone
    };
    public static ESCROW: IconType = {
        style: 'Escrow',
        tags: ['box', 'money', 'bill', 'escrow'],
        icon: 'fa-light fa-box-usd',
        reactIcon: faBoxUsd
    };
    public static EXPENSE: IconType = {
        style: 'Expense',
        tags: ['square', 'money', 'payment', 'bill'],
        icon: 'fa-light fa-usd-square',
        reactIcon: faUsdSquare
    };
    public static CAP: IconType = {
        style: 'CAP',
        tags: ['cap', 'university', 'help', 'learn'],
        icon: 'fa-light fa-graduation-cap',
        reactIcon: faGraduationCap
    }
    public static GROUP: IconType = {
        style: 'Group',
        tags: ['user', 'class', 'education', 'cle', 'teach', 'learn'],
        icon: 'fa-light fa-users-class',
        reactIcon: faUsersClass
    };
    public static INTERCOM: IconType = {
        style: 'Intercom',
        tags: ['marketing', 'intercom', 'chat'],
        icon: 'fa-brand fa-intercom',
        reactIcon: faIntercom
    };
    public static LEAD: IconType = {
        style: 'Lead',
        tags: ['money', 'sack', 'dollar', 'lead', 'sales'],
        icon: 'fa-light fa-sack-dollar',
        reactIcon: faSackDollar
    };
    public static LEARN: IconType = {
        style: 'Learn',
        tags: ['cle', 'learn', 'teach', 'class'],
        icon: 'fa-light fa-chalkboard-teacher',
        reactIcon: faChalkboardTeacher
    };
    public static LINK: IconType = {style: 'Link', tags: ['link'], icon: 'fa-light fa-link', reactIcon: faLink};
    public static LIST: IconType = {style: 'List', tags: ['list', 'todo'], icon: 'fa-light fa-list', reactIcon: faList};
    public static LOCK: IconType = {
        style: 'Lock',
        tags: ['lock', 'private', 'secure', 'safe'],
        icon: 'fa-duotone fa-solid fa-lock',
        dataFaTransform: 'shrink-1',
        reactIcon: faLock
    };
    public static LOCK_KEYHOLE: IconType = {
        style: 'LockKeyhole',
        tags: ['lock', 'private', 'secure', 'safe'],
        icon: 'fa-light fa-lock',
        dataFaTransform: 'shrink-1',
        reactIcon: faLockKeyhole
    }
    public static LOGIN: IconType = {
        style: 'Login',
        tags: ['power', 'off', 'login'],
        icon: 'fa-regular fa-power-off',
        reactIcon: faPowerOff
    };
    public static LOGOUT: IconType = {
        style: 'Logout',
        tags: ['power', 'off', 'logout'],
        icon: 'fa-regular fa-power-off',
        reactIcon: faPowerOff
    };
    public static LONG_ARROW_DOWN: IconType = {
        style: 'LongArrowDown',
        tags: ['arrow', 'down'], icon: 'fa-light fa-long-arrow-down',
        reactIcon: faLongArrowDown
    };
    public static LONG_ARROW_UP: IconType = {
        style: 'LongArrowUp',
        tags: ['arrow', 'up'],
        icon: 'fa-light fa-long-arrow-up',
        reactIcon: faLongArrowUp
    };
    public static IM: IconType = {
        style: 'IM',
        tags: ['messaging', 'communicate', 'text', 'sms'],
        icon: 'fa-light fa-comments',
        reactIcon: faComments
    };
    public static MESSAGE: IconType = {
        style: 'Message',
        tags: ['message', 'communicate', 'text', 'sms'],
        icon: 'fa-light fa-message',
        reactIcon: faMessage
    };
    public static MESSAGES: IconType = {
        style: 'Messages',
        tags: ['message', 'communicate', 'text', 'sms'],
        icon: 'fa-light fa-messages',
        reactIcon: faMessages
    };

    public static INDETERMINATE: IconType = {
        style: 'Indeterminate',
        tags: ['stop', 'sign'],
        icon: 'fa-solid fa-stop',
        reactIcon: faStop
    };
    public static MAD: IconType = {
        style: 'Mad',
        tags: ['mad', 'angry', 'emoji'],
        icon: 'fa-light fa-face-angry',
        reactIcon: faFaceAngry,
        color: 'maroon',
        level: 3
    };
    public static MAGNIFY_PLUS: IconType = {
        style: 'MagnifyPlus',
        tags: ['magnify', 'plus', 'large'],
        icon: 'fa-light fa-search-plus',
        reactIcon: faSearchPlus
    };
    public static MAGNIFY_MINUS: IconType = {
        style: 'MagnifyMinus',
        tags: ['magnify', 'minus', 'small'],
        icon: 'fa-light fa-search-minus',
        reactIcon: faSearchMinus
    };
    public static MAGNIFY_LOCATION: IconType = {
        style: 'MagnifySpecific',
        tags: ['magnify', 'location'], icon: 'fa-light fa-search-location',
        reactIcon: faSearchLocation
    };
    public static MAIL: IconType = {
        style: 'Mail',
        tags: ['mail', 'email', 'communicate', 'send', 'receive'],
        icon: 'fa-light fa-mail',
        reactIcon: faMailbox
    };
    public static MARKETING: IconType = {
        style: 'Marketing',
        tags: ['marketing', 'megaphone', 'shout', 'loud'],
        icon: 'fal-fa-megaphone',
        reactIcon: faMegaphone
    };
    public static MENU: IconType = {style: 'Menu', tags: ['bars', 'menu'], icon: 'fa-light fa-bars', reactIcon: faBars};
    public static MERGE: IconType = {
        style: 'Merge',
        tags: ['code', 'merge'],
        icon: 'fa-light fa-code-merge',
        reactIcon: faCodeMerge
    };
    public static MILESTONES: IconType = {
        style: 'Milestones',
        tags: ['milestone', 'marker', 'task'],
        icon: 'fa-light fa-tasks',
        reactIcon: faTasks
    };
    public static MOBILE: IconType = {
        style: 'Mobile',
        tags: ['phone', 'mobile', 'os', 'android'],
        icon: 'fa-light fa-mobile-android',
        reactIcon: faMobileAndroid
    };
    public static NEWSLETTER: IconType = {
        style: 'Newsletter',
        tags: ['marketing', 'newsletter'],
        icon: 'fa-light fa-paper-plane',
        reactIcon: faPaperPlane
    };
    public static NETWORK: IconType = {
        style: 'Network',
        tags: ['chart', 'graph', 'analytics'],
        icon: 'fa-light fa-chart-network',
        reactIcon: faChartNetwork
    };
    public static NODE: IconType = {
        style: 'Node',
        tags: ['network', 'node'],
        icon: 'fa-light fa-share-nodes',
        reactIcon: faShareNodes
    };
    public static NOPE: IconType = {
        style: 'Nope',
        tags: ['ban', 'nope', 'no', 'limit'],
        icon: 'fa-light fa-ban',
        dataFaTransform: 'shrink-3',
        reactIcon: faBan
    };
    public static NOTES: IconType = {
        style: 'Notes',
        tags: ['square', 'pen', 'edit'],
        icon: 'fa-light fa-pen-square',
        reactIcon: faPenSquare
    };
    public static OWNER: IconType = {
        style: 'Owner',
        tags: ['user', 'cowboy', 'owner'],
        icon: 'fa-light fa-user-cowboy',
        reactIcon: faUserCowboy
    };
    public static ATTORNEY: IconType = {
        style: 'Attorney',
        tags: ['attorney', 'letter', 'alpha'],
        icon: 'fa-duotone fa-solid fa-square-a',
        reactIcon: faSquareA
    };
    public static PARALEGAL: IconType = {
        style: 'Paralegal',
        tags: ['paralegal', 'letter', 'p'],
        icon: 'fa-duotone fa-solid fa-square-p',
        reactIcon: faSquareP
    };

    public static PARTY: IconType = {
        style: 'Party',
        tags: ['party', 'emoji', 'happy'],
        icon: 'fa-light fa-party-horn',
        reactIcon: faPartyHorn,
        color: 'purple',
        level: 1
    };

    public static OTHER: IconType = {
        style: 'Other',
        tags: ['circle', 'question'],
        icon: 'fa-light fa-circle-question',
        reactIcon: faCircleQuestion
    };
    public static PARKING: IconType = {
        style: 'Parking',
        tags: ['circle', 'parking'],
        icon: 'far-parking-circle',
        reactIcon: faParkingCircle
    };
    public static PASSPORT: IconType = {
        style: 'Passport',
        tags: ['id', 'identification', 'passport'],
        icon: 'fa-light fa-passport',
        reactIcon: faPassport
    };
    public static PEACE: IconType = {
        style: 'Peace',
        tags: ['peace', 'hippie'],
        icon: 'fa-light fa-peace',
        reactIcon: faPeace
    };
    public static PENCIL: IconType = {
        style: 'Pencil',
        tags: ['edit', 'pencil'],
        icon: 'fa-light fa-pencil',
        reactIcon: faPencil
    };
    public static PERIODICAL: IconType = {
        style: 'Periodical',
        tags: ['marketing', 'newspaper', 'periodical'],
        icon: 'fa-light fa-newspaper',
        reactIcon: faNewspaper
    };
    public static PAUSE: IconType = {
        style: 'Pause',
        tags: ['circle', 'pause'],
        icon: 'fa-light fa-pause-circle',
        reactIcon: faPauseCircle
    };
    public static PERCENTAGE: IconType = {
        style: 'Percentage',
        tags: ['percent', 'percentage'],
        icon: 'fa-light fa-percentage',
        reactIcon: faPercentage
    };
    public static PERSON_SIGN: IconType = {
        style: 'PersonSign',
        tags: ['person', 'sign'],
        icon: 'fa-light fa-person-sign',
        reactIcon: faPersonSign
    };
    public static PHONE: IconType = {style: 'Phone', tags: ['phone'], icon: 'fa-light fa-phone', reactIcon: faPhone};
    public static PIGGY_BANK: IconType = {
        style: 'PiggyBank',
        tags: ['financial', 'pig', 'piggy', 'bank', 'saving'],
        icon: 'fa-light fa-piggy-bank',
        reactIcon: faPiggyBank
    };
    public static PLAN: IconType = {style: 'Plan', tags: ['map', 'sign'], icon: 'fa-solid fa-map-signs', reactIcon: faMapSigns};
    public static PLAY: IconType = {
        style: 'Play',
        tags: ['circle', 'play', 'start'],
        icon: 'fa-solid fa-play-circle',
        reactIcon: faPlayCircle
    };
    public static PRESENT: IconType = {
        style: 'Present',
        tags: ['present', 'presentation'],
        icon: 'fa-light fa-presentation',
        reactIcon: faPresentation
    };
    public static POO: IconType = {
        style: 'Poo',
        tags: ['poo', 'emoji'],
        icon: 'fa-light fa-poo',
        reactIcon: faPoo,
        color: '#885f01',
        level: 3
    };
    public static PORTAL: IconType = {
        style: 'Portal',
        tags: ['browser', 'portal'],
        icon: 'fa-duotone fa-solid fa-browser',
        reactIcon: faBrowserDuotone
    };
    public static PODCAST: IconType = {
        style: 'Podcast',
        tags: ['marketing', 'podcast'],
        icon: 'fa-duotone fa-solid fa-podcast',
        reactIcon: faPodcast
    };
    public static UNPORTAL: IconType = {
        style: 'UnPortal',
        tags: ['browser'],
        icon: 'fa-duotone fa-solid fa-browser',
        reactIcon: faBrowserDuotone
    };
    public static PRINT: IconType = {style: 'Print', tags: ['print', 'printer'], icon: 'fa-light fa-print', reactIcon: faPrint};
    public static PRIVATE: IconType = {
        style: 'Private',
        tags: ['private', 'secure', 'lock'],
        icon: 'fa-duotone fa-solid fa-key',
        reactIcon: faKey
    };
    public static PROFILE: IconType = {
        style: 'Profile',
        tags: ['circle', 'user', 'profile', 'person'], icon: 'fa-light fa-user-circle',
        dataFaTransform: 'grow-1',
        reactIcon: faUserCircle
    };
    public static QUESTION: IconType = {
        style: 'Question',
        tags: ['circle', 'question'],
        icon: 'fa-regular fa-circle-question',
        reactIcon: faCircleQuestion
    };
    public static QUESTION_FILLED: IconType = {
        style: 'QuestionFilled',
        tags: ['circle', 'question'], icon: 'fa-solid fad-circle-question',
        reactIcon: faCircleQuestionSolid
    };
    public static READ: IconType = {
        style: 'Read',
        tags: ['book', 'read', 'learn', 'cle'],
        icon: 'fa-light fa-book-reader',
        reactIcon: faBookReader
    };
    public static RECURRING: IconType = {
        style: 'Recurring',
        tags: ['repeat', 'recur'],
        icon: 'fa-light fa-repeat',
        reactIcon: faRepeat
    };
    public static REDACT: IconType = {
        style: 'Redact',
        tags: ['redact', 'remove', 'format'],
        icon: 'fa-light fa-remove-format',
        reactIcon: faRemoveFormat
    };
    public static RUBBER_BAND: IconType = {style: 'RubberBand', tags: ['ban', 'band'], icon: 'fa-light fa-ban', reactIcon: faBan};
    public static MONEY_IN: IconType = {
        style: 'MoneyIn',
        tags: ['money', 'hand', 'hold', 'cash', 'sale'],
        icon: 'fa-solid fa-hand-holding-usd',
        reactIcon: faHandHoldingUsd
    };
    public static OPTION_MENU: IconType = {
        style: 'OptionMenu',
        tags: ['square', 'menu', 'options'],
        icon: 'fa-light fa-square-sliders',
        reactIcon: faSquareSliders
    };
    public static REFERRAL_OUT: IconType = {
        style: 'ReferralOut',
        tags: ['hand', 'seed', 'plant', 'lead'], icon: 'fa-solid fa-hand-holding-seedling',
        reactIcon: faHandHoldingSeedling
    };
    public static RELATIONSHIPS: IconType = {
        style: 'Relationships',
        tags: ['user'],
        icon: 'fa-light fa-users',
        reactIcon: faUsers
    };
    public static RESEARCH: IconType = {
        style: 'Research',
        tags: ['file', 'search'],
        icon: 'fa-light fa-file-search',
        reactIcon: faFileSearch
    };
    public static RESUME: IconType = {
        style: 'Resume',
        tags: ['file', 'user'],
        icon: 'fa-light fa-file-user',
        reactIcon: faFileUser
    };
    public static SALES: IconType = {
        style: 'Sales',
        tags: ['money', 'sack', 'dollar', 'sale'],
        icon: 'fa-light fa-sack-dollar',
        reactIcon: faSackDollar
    };
    public static SAFARI: IconType = {style: 'Safari', tags: ['browser', 'safari'], icon: 'fa-brand fa-safari', reactIcon: faSafari};
    public static SCALES: IconType = {
        style: 'Scales',
        tags: ['scale', 'justice', 'judge', 'court'],
        icon: 'fa-light fa-balance-scale',
        reactIcon: faBalanceScale
    };
    public static SCAN: IconType = {
        style: 'Scan',
        tags: ['scan', 'import'],
        icon: 'fa-light fa-file-import',
        reactIcon: faFileImport
    };
    public static SKULL: IconType = {
        style: 'Skull',
        tags: ['skull', 'bad', 'warning', 'danger'],
        icon: 'fa-duotone fa-solid fa-skull-crossbones',
        reactIcon: faSkullCrossbones
    };
    public static SEARCH: IconType = {style: 'Search', tags: ['search'], icon: 'fa-light fa-search', reactIcon: faSearch};
    public static SEARCH_CANCEL: IconType = {
        style: 'SearchCancel',
        tags: ['search', 'cancel'],
        icon: 'fa-solid fa-times-circle',
        reactIcon: faTimesCircleSolid
    };
    public static SEARCH_CANCEL_DUO: IconType = {
        style: 'SearchCancelDuo',
        tags: ['search', 'cancel'],
        icon: 'fa-duotone fa-solid fa-times-circle',
        reactIcon: faTimesCircleDuotone
    };
    public static SIGNUP: IconType = {
        style: 'SignUp',
        tags: ['file', 'sign', 'signature'],
        icon: 'fa-light fa-file-signature',
        reactIcon: faFileSignature
    };
    public static SIGNATURE: IconType = {
        style: 'Signature',
        tags: ['sign', 'signarture'],
        icon: 'fa-light fa-signature',
        reactIcon: faSignature
    };
    public static SMILE_DIAL: IconType = {
        style: 'SmileDial',
        tags: ['marekting', 'smile', 'dial', 'comment'],
        icon: 'fa-light fa-comment-smile',
        reactIcon: faCommentSmile
    };
    public static SOCIAL: IconType = {
        style: 'Social',
        tags: ['square', 'share'],
        icon: 'fa-light fa-share-alt-square',
        reactIcon: faShareAltSquare
    };
    public static SORT_FILTER: IconType = {
        style: 'SortFilter',
        tags: ['sort', 'filter', 'table'],
        icon: 'fa-light fa-filter-list',
        reactIcon: faFilterList
    };
    public static SORT_DOWN: IconType = {
        style: 'SortDown',
        tags: ['sort', 'down', 'descend'], icon: 'fa-light fa-sort-amount-down-alt',
        reactIcon: faSortAmountDownAlt
    };
    public static SORT_UP: IconType = {
        style: 'SortUp',
        tags: ['sort', 'up', 'ascend'],
        icon: 'fa-light fa-sort-amount-up-alt',
        reactIcon: faSortAmountUpAlt
    };
    public static SORT_DOWN_ALPHA: IconType = {
        style: 'SortDownAlpha',
        tags: ['sort', 'down', 'alpha'], icon: 'fa-light fa-sort-alpha-down',
        reactIcon: faSortAlphaDown
    };
    public static SORT_UP_ALPHA: IconType = {
        style: 'SortUpAlpha',
        tags: ['sort', 'up', 'alpha'], icon: 'fa-light fa-sort-alpha-down-alt',
        reactIcon: faSortAlphaDownAlt
    };
    public static SORT_DOWN_NUMERIC: IconType = {
        style: 'SortDownNumeric',
        tags: ['sort', 'down', 'numeric'], icon: 'fa-light fa-sort-numeric-down',
        reactIcon: faSortNumericDown
    };
    public static SORT_UP_NUMERIC: IconType = {
        style: 'SortUpNumeric',
        tags: ['sort', 'up', 'numeric'], icon: 'fa-light fa-sort-numeric-down-alt',
        reactIcon: faSortNumericDownAlt
    };
    public static SPONSORSHIP: IconType = {
        style: 'Sponsorship',
        tags: ['sales', 'win', 'trophy'],
        icon: 'fa-light fa-trophy',
        reactIcon: faTrophy
    };
    public static SPINNER: IconType = {
        style: 'Spinner',
        tags: ['spin', 'wait', 'hold'],
        icon: 'fa-solid fa-spinner',
        reactIcon: faSpinner,
        animate: 'spin'
    };
    public static STAMP: IconType = {
        style: 'Notarize',
        tags: ['file', 'stamp'],
        icon: 'fa-duotone fa-solid fa-stamp',
        reactIcon: faStamp
    };
    public static STAR: IconType = {
        style: 'Star',
        tags: ['star', 'favorite'],
        icon: 'fa-solid fa-star',
        dataFaTransform: 'shrink-3',
        reactIcon: faStarSolid
    };
    public static STAR_FILLED: IconType = {
        style: 'StarFilled',
        tags: ['star', 'favorite'],
        icon: 'fa-duotone fa-solid fa-star',
        reactIcon: faStarDuotone
    };
    public static STAR_OUTLINE: IconType = {
        style: 'StarOutline',
        tags: ['star'], icon: 'fa-light fa-star',
        dataFaTransform: 'shrink-3',
        reactIcon: faStar
    };
    public static STOPWATCH: IconType = {
        style: 'Stopwatch',
        tags: ['time', 'stopwatch', 'watch', 'timer'],
        icon: 'fa-light fa-stopwatch',
        reactIcon: faStopwatch
    };
    public static SUPPORT: IconType = {
        style: 'Support',
        tags: ['support', 'life', 'ring', 'circle'],
        icon: 'fa-light fa-life-ring',
        reactIcon: faLifeRing
    };
    public static SYNC: IconType = {
        style: 'Sync',
        tags: ['sync'],
        icon: 'fa-duotone fa-solid fa-sync-alt',
        reactIcon: faSyncAltDuotone
    };
    public static SYNC_LIGHT: IconType = {
        style: 'SyncLight',
        tags: ['sync'],
        icon: 'fa-light fa-sync-alt',
        reactIcon: faSyncAlt
    };
    public static SYNC_LIGHT_SPIN: IconType = {
        style: 'SyncLight',
        tags: ['sync', 'spin'], icon: 'fa-light fa-sync-alt',
        reactIcon: faSyncAlt,
        animate: 'spin'
    };
    public static BLOCK_VIEW: IconType = {
        style: 'BlockView',
        tags: ['table', 'header', 'spreadsheet'],
        icon: 'fa-duotone fa-solid fa-th',
        reactIcon: faThDuotone
    };
    public static TABLE: IconType = {
        style: 'Table',
        tags: ['table', 'spreadsheet'],
        icon: 'fa-light fa-table',
        reactIcon: faTable
    };
    public static TACHOMETER: IconType = {
        style: 'Tachometer',
        tags: ['speed', 'tachometer'],
        icon: 'fa-duotone fa-solid fa-tachometer',
        reactIcon: faTachometer
    };
    public static TAG: IconType = {style: 'Tag', tags: ['tag'], icon: 'fa-light fa-tag', reactIcon: faTag};
    public static TAGS: IconType = {
        style: 'Tags',
        tags: ['tag', 'tags'],
        icon: 'fa-light fa-tags',
        dataFaTransform: 'shrink-1',
        reactIcon: faTags
    };
    public static TASKS: IconType = {
        style: 'Tasks',
        tags: ['square', 'check', 'task', 'finish', 'complete'],
        icon: 'fa-light fa-check-square',
        reactIcon: faCheckSquare
    };
    public static THUMBS_UP: IconType = {
        style: 'ThumbsUp',
        tags: ['thumb', 'up'],
        icon: 'fa-light fa-thumbs-up',
        reactIcon: faThumbsUp
    };
    public static THUMBS_DOWN: IconType = {
        style: 'ThumbsDown',
        tags: ['thumb', 'down'],
        icon: 'fa-light fa-thumbs-down',
        reactIcon: faThumbsDown
    };
    public static TIME_SORT_ASC: IconType = {
        style: 'TimeSortAsc',
        tags: ['sort', 'time', 'ascend'],
        icon: 'fa-light fa-timeline-arrow',
        rotation: 90,
        reactIcon: faTimelineArrow
    };
    public static TIME_SORT_DESC: IconType = {
        style: 'TimeSortDesc',
        tags: ['sort', 'time', 'descend'],
        icon: 'fa-light fa-timeline-arrow',
        rotation: 270,
        reactIcon: faTimelineArrow
    };
    public static TOGGLE_OFF: IconType = {
        style: 'ToggleOff',
        tags: ['toggle', 'off'],
        icon: 'fa-solid fa-toggle-off',
        reactIcon: faToggleOff
    };
    public static TOGGLE_ON: IconType = {
        style: 'ToggleOn',
        tags: ['toggle', 'on'],
        icon: 'fa-solid fa-toggle-on',
        reactIcon: faToggleOn
    };
    public static TRANSACTION: IconType = {
        style: 'Transaction',
        tags: ['transaction'],
        icon: 'fa-light fa-money-check-alt',
        reactIcon: faMoneyCheckAlt
    };
    public static TWITTER: IconType = {
        style: 'TwitterI',
        tags: ['marketing', 'twitter'],
        icon: 'fa-brand fa-twitter',
        reactIcon: faTwitter
    };
    public static SUPERUSER: IconType = {
        style: 'SuperUser',
        tags: ['user', 'ninja', 'expert', 'witness'],
        icon: 'fa-light fa-user-ninja',
        reactIcon: faUserNinja
    };
    public static UNCHECKED: IconType = {
        style: 'Unchecked',
        tags: ['square', 'incomplete', 'unfinished'],
        icon: 'fa-light fa-square',
        reactIcon: faSquare
    };
    public static UNCHECKEDSOLID: IconType = {
        style: 'Unchecked',
        tags: ['square', 'incomplete', 'unfinished'],
        icon: 'fa-duotone fa-solid fa-square',
        reactIcon: faSquareDuotone
    };
    public static UNLOCK: IconType = {
        style: 'Unlock',
        tags: ['lock', 'unlock', 'unprotect', 'insecure'],
        icon: 'fa-duotone fa-solid fa-unlock',
        dataFaTransform: 'shrink-3',
        reactIcon: faUnlock
    };
    public static UNLOCK_KEYHOLE: IconType = {
        style: 'UnlockKeyhole',
        tags: ['lock', 'unlock', 'unprotect', 'insecure'],
        icon: 'fa-duotone fa-solid fa-unlock',
        dataFaTransform: 'shrink-3',
        reactIcon: faUnlockKeyhole
    };
    public static USER: IconType = {
        style: 'User',
        tags: ['user'],
        icon: 'fa-light fa-user',
        reactIcon: faUser
    };
    public static USERS: IconType = {
        style: 'Users',
        tags: ['user', 'users'],
        icon: 'fa-light fa-users',
        dataFaTransform: 'shrink-1',
        reactIcon: faUsers
    };
    public static VIEW: IconType = {
        style: 'View',
        tags: ['eye', 'view', 'visible'],
        icon: 'fa-duotone fa-eye',
        reactIcon: faEye
    };
    public static VOMIT: IconType = {
        style: 'Vomit',
        tags: ['vomit', 'emoji'],
        icon: 'fa-light fa-face-vomit',
        reactIcon: faFaceVomit,
        color: 'darkgreen',
        level: 3
    }
    public static HIDE: IconType = {
        style: 'Hide',
        tags: ['eye', 'hide', 'invisible'],
        icon: 'fa-duotone fa-solid fa-eye-slash',
        reactIcon: faEyeSlash
    };
    public static UNDO: IconType = {
        style: 'Undo',
        tags: ['undo'],
        icon: 'fa-duotone fa-solid fa-undo',
        reactIcon: faUndo
    };

    public static CHARGE: IconType = {
        style: 'Charge',
        tags: ['circle', 'money', 'cash', 'charge'],
        icon: 'fa-light fa-usd-circle',
        reactIcon: faUsdCircle
    };
    public static VOID: IconType = {
        style: 'Void',
        tags: ['ban', 'void', 'reject'],
        icon: 'fa-light fa-ban',
        reactIcon: faBan
    };
    public static REFUND: IconType = {
        style: 'Refund',
        tags: ['undo', 'refund'],
        icon: 'fa-light fa-undo-alt',
        reactIcon: faUndoAlt
    };

    public static WRITE: IconType = {
        style: 'Write',
        tags: ['write', 'comment', 'edit'],
        icon: 'fa-light fa-comment-edit',
        reactIcon: faCommentEdit
    };
    public static WALKING: IconType = {
        style: 'Walking',
        tags: ['walk', 'walking'],
        icon: 'fa-light fa-walking',
        reactIcon: faWalking
    };
    public static WALK_IN: IconType = {
        style: 'WalkIn',
        tags: ['marketing', 'walk-in', 'walk'],
        icon: 'fa-light fa-person-to-door',
        reactIcon: faPersonToDoor
    };
    public static WORK_IN_PROGRESS: IconType = {
        style: 'WorkInProgress',
        tags: ['wip', 'construction', 'progress'], icon: 'fa-light fa-construction',
        reactIcon: faConstruction
    };
    public static WWW: IconType = {
        style: 'Web',
        tags: ['browser', 'www', 'web'],
        icon: 'fa-light fa-browser',
        reactIcon: faBrowser
    };

// Banking
    public static PAYMENT: IconType = {
        style: 'Payment',
        tags: ['transaction', 'payment', 'money'], icon: 'fa-solid fa-money-check-alt',
        reactIcon: faMoneyCheckAlt
    };
    public static WITHDRAW_FUNDS: IconType = {
        style: 'Withdraws',
        tags: ['transaction', 'withdraw', 'withdrawal', 'money', 'check', 'edit'],
        icon: 'fa-solid fa-money-check-edit-alt',
        reactIcon: faMoneyCheckEditAlt
    };
    public static WINDOW: IconType = {
        style: 'Window',
        tags: ['window', 'application'],
        icon: 'fa-duotone fa-solid fa-window',
        reactIcon: faWindow
    };
    public static TRANSFER_FUNDS: IconType = {
        style: 'Transfer',
        tags: ['transaction', 'transfer', 'exchange'],
        icon: 'fa-regular fa-exchange',
        reactIcon: faExchange
    };
    public static CREDIT_CARD: IconType = {
        style: 'CreditCard',
        tags: ['money', 'credit', 'card'],
        icon: 'fa-duotone fa-solid fa-credit-card',
        reactIcon: faCreditCard
    };
    public static CREDIT_CARD_PLAIN: IconType = {
        style: 'CreditCard',
        tags: ['money', 'credit', 'card'],
        icon: 'fa-thin fa-credit-card',
        reactIcon: faCreditCard
    };
    public static PAYMENT_PROCESSING_ON: IconType = {
        style: 'PaymentProcessingOn',
        tags: ['money', 'credit', 'card'],
        icon: 'fa-duotone fa-solid fa-credit-card',
        duotone: true,
        faPrimaryColor: 'green',
        faSecondaryColor: 'black',
        reactIcon: faCreditCardDuotone
    };
    public static PAYMENT_PROCESSING_OFF: IconType = {
        style: 'PaymentProcessingOff',
        tags: ['money', 'credit', 'card'],
        icon: 'fa-duotone fa-solid fa-credit-card',
        duotone: true,
        faPrimaryColor: 'red',
        faSecondaryColor: 'black',
        reactIcon: faCreditCardDuotone
    };
    public static WARN: IconType = {
        style: 'Warn',
        tags: ['Warn', 'Warning', 'Yield'],
        icon: 'fa-thin fa-warning',
        reactIcon: faWarning
    }
    public static VAULT: IconType = {
        style: 'Vault',
        tags: ['Safe', 'Vault', 'Trust'],
        icon: 'fa-duotone fa-solid fa-vault',
        reactIcon: faVault
    };
    public static VAULT_DUO: IconType = {
        style: 'Vault',
        tags: ['Safe', 'Vault', 'Trust'],
        icon: 'fa-duotone fa-solid fa-vault',
        reactIcon: faVaultDuotone
    };
}

export default Icons;